<template>
	<div class="device-account">
		<gc-header :header-data="tenantType == 1 ? 'waterAccountManage' : 'accountManage'">
			<template #ops>
				<el-button
					v-has="'monitor:device:account:maintain'"
					v-click-blur
					style="width: 100px"
					type="primary"
					@click="addNewDevice"
				>
					设备新增
				</el-button>
				<el-button
					type="primary"
					v-has="'monitor:ledger:device:batch-update'"
					v-click-blur
					style="width: 100px"
					v-if="tenantType == 1"
					:disabled="selection.length === 0"
					@click="emitBatchEdit"
				>
					批量编辑
				</el-button>
				<el-button v-click-blur @click="handleDeviceExport">导 出</el-button>
				<el-button
					v-if="showUasyncBtn"
					v-click-blur
					style="width: 150px"
					type="warning"
					plain
					@click="showUasyncDialog = true"
				>
					一键同步C端用户信息
				</el-button>
			</template>
		</gc-header>
		<div class="content">
			<div class="left" v-if="tenantType == 1">
				<GcRegionTree v-model="params.waterRegionCode" :initSelect="false" />
			</div>
			<div class="right">
				<div class="filter">
					<gc-custom-search
						class="search"
						key-word-width="103px"
						:key-word-option="keyWordOption"
						:key-word.sync="keyWord"
						:search.sync="params['first']"
						width="300px"
					></gc-custom-search>
					<gc-custom-search
						v-if="tenantType == 1"
						class="search"
						key-word="客户类型"
						type="select"
						width="240px"
						:search-option="searchOptions['userType']"
						:search.sync="params['userType']"
						:search-option-key="{ label: 'name', value: 'defaultValue' }"
						needAllForSearch
					></gc-custom-search>
					<gc-custom-search
						v-if="tenantType == 0"
						class="search"
						key-word="设备类型"
						type="select"
						width="300px"
						filterable
						:search-option="searchOptions['deviceTypeId']"
						:search.sync="params['deviceTypeId']"
						needAllForSearch
					></gc-custom-search>
					<gc-custom-search
						class="search"
						key-word="设备状态"
						type="select"
						width="240px"
						:search-option="searchOptions['deviceStatus']"
						:search-option-key="{ label: 'name', value: 'defaultValue' }"
						:search.sync="params['deviceStatus']"
						needAllForSearch
					></gc-custom-search>
					<gc-custom-search
						class="search"
						key-word="安装日期"
						type="date"
						datePickerType="daterange"
						width="350px"
						:search.sync="params['installDate']"
					></gc-custom-search>
					<div class="btn-ops">
						<el-button v-click-blur type="primary" class="query" @click="query">查 询</el-button>
						<el-button v-click-blur @click="reset">重 置</el-button>
					</div>
					<gc-column-setting
						:storageKey="`localcolumn-device-account-${tenantType}`"
						:columns="columns"
						@update-column="data => (columns = data)"
					></gc-column-setting>
				</div>
				<div class="table" v-loading="loading">
					<gc-table
						ref="deviceTable"
						border
						showPage
						:need-type="tenantType == 1 ? 'selection' : ''"
						:total="total"
						:columns="columnList"
						:table-data="tableData"
						:current-page.sync="currentPage"
						:selectable="selectableFunc"
						:page-size="pageSize"
						@current-page-change="pageChange"
						@selectChange="handleSelectionChange"
						@size-change="handleSizeChange"
					></gc-table>
				</div>
			</div>
		</div>
		<gc-dialog
			ref="dialogBox"
			v-loading="dialogLoading"
			:width="dialogType === 'uninstall' ? '480px' : '900px'"
			:show.sync="showDialog"
			:title="title"
			@ok="okDialog"
			@cancel="cancelDialog"
			@close="closeDialog"
		>
			<DialogUninstall
				ref="dialogUninstall"
				v-if="dialogType === 'uninstall'"
				:params.sync="dialogParams"
			></DialogUninstall>
			<DialogContent
				ref="dialogContent"
				v-else
				:params.sync="dialogParams"
				:type="dialogType"
				:change-type="dialogChangeType"
				:show="showDialog"
				@send-dynomic-keys="getDynomicKeys"
			></DialogContent>
		</gc-dialog>

		<!--  -->
		<DialogUasync :show.sync="showUasyncDialog" />
		<BatchEdit :show.sync="showBatchEdit" :devices="selection" @refresh="reset" />
	</div>
</template>

<script>
import DialogContent from './DialogContent'
import DialogUninstall from './DialogUninstall'
import DialogUasync from './DialogUasync'
import {
	apiGetDeviceList,
	apiAddDevice,
	apiInstallDevice,
	apiUninstallDevice,
	apiDeleteDevice,
	apiChangeDevice,
	apiGetDeviceTypeList,
} from '@/apis/account.api'
import { handleParams } from '@/utils'
import { isBlank } from '@/utils/validate'
import { mapActions } from 'vuex'
import { deviceDetailPermissions } from '@/config/const'
import { exportReport } from '@/apis/statisticAnalysis.api'
import { apiGetTenantConfigs } from '@/apis/tenant.api.js'
import { nameTrans } from '@/utils'
import BatchEdit from './components/BatchEdit.vue'
export default {
	name: 'deviceAccount',
	components: { DialogContent, DialogUninstall, DialogUasync, BatchEdit },
	data() {
		return {
			dynomicKeys: [],
			searchOptions: {
				userType: [],
				deviceTypeId: [],
				deviceStatus: [],
			},
			keyWord: 'deviceNo',
			params: {
				waterRegionCode: null,
				first: null,
				userType: null,
				deviceTypeId: null,
				deviceStatus: null,
				installDate: [],
			},
			tableData: [],
			total: 0,
			currentPage: 1,
			pageSize: 20,
			showDialog: false,
			title: '设备新增',
			dialogParams: {},
			dialogType: 'add',
			dialogChangeType: 0,
			pageParams: {},
			currentDeviceId: null,
			loading: false,
			dialogLoading: false,
			showUasyncBtn: false,
			showUasyncDialog: false,
			columns: [],
			selection: [],
			showBatchEdit: false,
			selectableFunc: row => {
				return row.deviceStatus == 1
			},
		}
	},

	created() {
		this.getTenantConfigs()
		if (this.tenantType == 0) {
			apiGetDeviceTypeList(this.tenantId).then(res => {
				let list = res.records || []
				let val = list.map(item => {
					return {
						value: item.deviceTypeId,
						label: item.deviceTypeName,
					}
				})
				this.searchOptions.deviceTypeId = val
			})
		}
		this.searchOptions.userType = this.$store.getters?.dataList?.userType || []
		this.searchOptions.deviceStatus = this.searchOptions.deviceStatus =
			this.$store.getters?.dataList?.deviceStatus || []

		this.tenantType == 1 && this.$set(this.params, 'waterRegionCode', this.userInfo.defaultWaterRegionCode)
		this.query()
	},

	methods: {
		...mapActions({
			judgeNeedPickOrg: 'user/judgeNeedPickOrg',
		}),
		handleSelectionChange(list) {
			this.selection = list
		},
		// 水租户批量编辑
		emitBatchEdit() {
			this.showBatchEdit = true
		},
		dealReRenderData(row) {
			let obj = {}
			for (var key in row) {
				let val = row[key]
				if (!isBlank(val)) {
					if (key === 'installDate') {
						obj[key] = new Date(val)
					}
					// else if (key === "latitude") {
					// obj["lngLat"] = `经度${row.longitude || ""}度，纬度${
					//   row.latitude || ""
					// }度`;
					// obj[key] = val;
					// }
					else {
						obj[key] = val
					}
				}
				obj.lngLat = {
					lng: row.longitude || undefined,
					lat: row.latitude || undefined,
				}
			}
			return obj
		},
		addNewDevice() {
			this.judgeNeedPickOrg().then(res => {
				if (res) {
					this.title = '设备新增'
					this.dialogType = 'add'
					this.showDialog = true
					this.$set(this.dialogParams, 'deviceStatus', 0)
					this.$set(this.dialogParams, 'sfdjlr', 0)
					this.$set(this.dialogParams, 'lngLat', { lng: '', lat: '' })
				}
			})
		},
		// 台账导出
		handleDeviceExport() {
			if (!this.tableData.length) {
				this.$message.warning('暂无表格数据')
				return
			}
			const params = this.getRequestParams()
			if (!params) return
			this.$toast('下载中，请稍后...')
			const data = {
				method: 'post',
				url: '/monitor/report/device-ledger/export/excel',
				fileName: '台账列表.xlsx',
				data: params,
			}
			exportReport(data, 'excel', '', this.$toast)
		},
		getTenantConfigs() {
			apiGetTenantConfigs({ tenantId: this.tenantId }).then(res => {
				const list = res.dataList || []
				list.forEach(item => {
					if (item.key === 'enable_uasync') {
						this.showUasyncBtn = item.value === 'true'
					}
				})
			})
		},
		changeDevice(row) {
			this.title = '设备修改'
			this.dialogParams = this.dealReRenderData(row)
			this.dialogType = 'change'
			this.dialogChangeType = this.dialogParams.deviceStatus
			this.showDialog = true
		},
		dealDevice(row, type) {
			this.currentDeviceId = row.deviceId
			if (type == 1) {
				this.dialogType = 'uninstall'
				this.title = '设备拆除'
			} else {
				this.title = '设备安装'
				this.dialogParams = this.dealReRenderData(row)
				this.dialogType = 'install'
				this.$set(this.dialogParams, 'deviceStatus', 1)
				console.log(this.dialogParams)
			}
			this.showDialog = true
		},
		delete(row) {
			this.$confirm('你确定要删除该设备吗？删除后将不可撤销。').then(() => {
				apiDeleteDevice(row.iotDeviceId).then(() => {
					this.$message.success('设备删除成功')
					this.reset()
				})
			})
		},
		checkDetail(row) {
			this.$router.push({
				path: 'detail',
				query: {
					deviceId: row.deviceId,
					deviceType: row.deviceTypeId,
				},
			})
		},
		getRequestParams() {
			const params = {}
			for (const key in this.params) {
				const value = this.params[key]
				if (!isBlank(value)) {
					if (key === 'installDate' && value.length) {
						params.installStartDate = this.dayjs(value[0]).format('YYYY-MM-DD')
						params.installEndDate = this.dayjs(value[1]).format('YYYY-MM-DD')
					}
					key === 'first' ? (params[this.keyWord] = value) : (params[key] = value)
				}
			}
			delete params.installDate
			if (params.address && params.address.length < 2) {
				this.$message.warning('安装地址不可以少于2个字符')
				return
			}
			params['tenantType'] = this.tenantType
			return params
		},
		getDeviceList(current) {
			const data = this.getRequestParams()
			if (!data) return
			data.current = current
			data.size = this.pageSize
			data.tenantType = this.tenantType
			this.loading = true
			apiGetDeviceList(data)
				.then(res => {
					this.tableData = res.records || []
					this.total = res.total
					this.currentPage = res.current
				})
				.catch(() => {})
				.finally(() => {
					this.loading = false
				})
		},
		query() {
			this.getDeviceList(1)
		},
		pageChange(current) {
			this.getDeviceList(current)
		},
		handleSizeChange(size) {
			this.pageSize = size
			this.getDeviceList(1)
		},
		reset() {
			this.keyWord = 'deviceNo'
			for (var key in this.params) {
				this.params[key] = null
			}
			this.tenantType == 1 && this.$set(this.params, 'waterRegionCode', this.userInfo.defaultWaterRegionCode)
			this.selection = []
			this.query()
		},
		getDynomicKeys(list) {
			this.dynomicKeys = list
		},
		okDialog() {
			let params = {
				dynamicContent: {},
			}
			for (var key in this.dialogParams) {
				let val = handleParams(this.dialogParams)[key]
				// dynomicKeys 为空传null或者''
				if (this.dynomicKeys.includes(key)) {
					if (val instanceof Date) {
						if (!isBlank(val)) {
							params.dynamicContent[key] = this.dayjs(val).format('YYYY-MM-DD')
						} else {
							params.dynamicContent[key] = null
						}
					} else {
						params.dynamicContent[key] = !isBlank(val) ? val : null
					}
				} else {
					if (!isBlank(val)) {
						if (val instanceof Date) {
							params[key] = this.dayjs(val).format('YYYY-MM-DD')
						} else {
							params[key] = val
						}
					}
				}
			}
			switch (this.dialogType) {
				case 'add':
					this.$refs?.dialogContent?.$refs.contentForm.validate((valid, obj) => {
						let lackList = Object.keys(obj)
						if (lackList.length) {
							this.$refs?.dialogContent?.showValidFalse(lackList[0])
						}
						if (valid && this.$refs.dialogContent.validateUseGas()) {
							this.dialogLoading = true
							apiAddDevice({
								...params,
								waterRegionCode:
									this.tenantType == 1
										? params.waterRegionCode || this.userInfo.defaultWaterRegionCode
										: undefined,
							})
								.then(() => {
									this.showDialog = false
									this.$message.success('设备注册成功')
									this.reset()
								})
								.finally(() => {
									this.dialogLoading = false
								})
						}
					})

					return
				case 'install':
					this.$refs?.dialogContent?.$refs.contentForm.validate((valid, obj) => {
						console.log('params: ', params)
						let lackList = Object.keys(obj)
						if (lackList.length) {
							this.$refs?.dialogContent?.showValidFalse(lackList[0])
						}
						if (valid && this.$refs.dialogContent.validateUseGas()) {
							this.dialogLoading = true
							apiInstallDevice(params)
								.then(() => {
									this.showDialog = false
									this.$message.success('设备安装成功')
									this.reset()
								})
								.finally(() => {
									this.dialogLoading = false
								})
						}
					})
					return
				case 'uninstall':
					this.$refs?.dialogUninstall?.$refs.uninstallForm.validate(valid => {
						if (valid) {
							params = {
								deviceId: this.currentDeviceId,
								uninstallReason: this.dialogParams.uninstallReason,
								uninstallDate: this.dayjs(this.dialogParams.uninstallDate).format('YYYY-MM-DD'),
							}
							this.dialogLoading = true
							apiUninstallDevice(params)
								.then(() => {
									this.showDialog = false
									this.$message.success('设备拆除成功')
									this.reset()
								})
								.finally(() => {
									this.dialogLoading = false
								})
						}
					})
					return
				case 'change':
					this.$refs?.dialogContent?.$refs.contentForm.validate((valid, obj) => {
						let lackList = Object.keys(obj)
						if (lackList.length) {
							this.$refs?.dialogContent?.showValidFalse(lackList[0])
						}
						if (valid && this.$refs.dialogContent.validateUseGas()) {
							this.dialogLoading = true
							apiChangeDevice(params)
								.then(() => {
									this.showDialog = false
									this.$message.success('设备修改成功')
									this.reset()
								})
								.finally(() => {
									this.dialogLoading = false
								})
						}
					})
					return
			}
		},
		cancelDialog() {
			this.showDialog = false
		},
		closeDialog() {
			this.$nextTick(() => {
				this.$refs.dialogUninstall?.$refs.uninstallForm.clearValidate()
				let dialogContent = this.$refs.dialogContent
				if (dialogContent) {
					dialogContent.basicFormItems = []
					dialogContent.$refs.contentForm.clearValidate()
					dialogContent.resetUseGas()
					if (dialogContent.showMapBox) {
						dialogContent.showMapBox = false
						dialogContent.$refs.mapSelect.initMap()
					}
				}
			})
			this.showDialog = false
			this.dialogParams = {}
		},
	},

	computed: {
		userInfo() {
			return this.$store.state.user.userInfo
		},
		tenantId() {
			return this.$store.getters.userInfo.tenantId || null
		},
		isOnlineOptions() {
			return this.$store.getters.dataList.isOnline || []
		},
		isAlarmOptions() {
			return this.$store.getters.dataList.isAlarm || []
		},
		isAlarmMap() {
			return this.$store.getters.dataList.isAlarm.reduce((prev, cur) => {
				return {
					...prev,
					[cur.defaultValue]: cur.name,
				}
			}, {})
		},
		tenantType() {
			// 0-燃气租户 1-水务租户
			return this.$store.getters.userInfo.tenantType || '0'
		},
		homeTypeMap() {
			return (
				this.$store.getters.dataList?.homeType?.reduce((prev, cur) => {
					return {
						...prev,
						[cur.defaultValue]: cur.name,
					}
				}, {}) || {}
			)
		},
		keyWordOption() {
			if (this.tenantType == 1) {
				return [
					{
						label: '设备编号',
						value: 'deviceNo',
					},
					{
						label: '客户名称',
						value: 'userName',
					},
					{
						label: '用户号',
						value: 'userNo',
					},
					{
						label: '安装地址',
						value: 'address',
					},
				]
			} else {
				return [
					{
						label: '设备编号',
						value: 'deviceNo',
					},
					{
						label: '条形编码',
						value: 'gasDeviceNo',
					},
					{
						label: '客户名称',
						value: 'userName',
					},
					{
						label: '用户号',
						value: 'userNo',
					},
					{
						label: '安装地址',
						value: 'address',
					},
				]
			}
		},

		columnList() {
			return this.columns.filter(o => o.selected)
		},
	},

	watch: {
		'params.waterRegionCode': {
			handler(val) {
				if (val) {
					this.query()
				}
			},
		},

		tenantType: {
			handler(newVal) {
				if (newVal === '1') {
					this.columns = [
						{
							key: 'deviceNo',
							name: '设备编号',
							tooltip: true,
							fixed: 'left',
							dragDisable: true,
							disabledSelect: true,
						},
						{
							key: 'waterRegionName',
							name: '所属区域',
							tooltip: true,
							dragDisable: true,
						},
						{
							key: 'userTypeName',
							name: '客户类型',
							tooltip: true,
							dragDisable: true,
						},
						{
							key: 'userNo',
							name: '用户号',
							tooltip: true,
							dragDisable: true,
						},
						{
							key: 'homeTypeName',
							name: '是否独居老人',
							render: (h, row) => {
								return h('span', {}, !isBlank(row.homeType) ? this.homeTypeMap[row.homeType] : '--')
							},
						},
						{
							key: 'username',
							name: '客户名称',
							tooltip: true,
							dragDisable: true,
						},
						{
							key: 'deviceTypeName',
							name: '设备类型名称',
							tooltip: true,
							dragDisable: true,
						},
						{
							key: 'addressName',
							name: '安装地址',
							tooltip: true,
							minWidth: 200,
							selected: false,
						},
						{
							key: 'installDate',
							name: '安装日期',
							selected: false,
							width: 160,
						},
						{
							key: 'manufacturerName',
							name: '设备厂商',
							tooltip: true,
						},
						{
							key: 'deviceStatusName',
							name: '设备状态',
							render: (h, row) => {
								let val = row.deviceStatus
								let statusMap = {
									0: { class: 'dot-error', text: '待安装' },
									1: { class: 'dot-success', text: '已安装' },
									2: { class: 'dot-message', text: '已拆除' },
								}
								return h(
									'span',
									{ class: isBlank(val) ? '' : statusMap[val]['class'] },
									isBlank(val) ? '--' : statusMap[val]['text'],
								)
							},
						},
						{
							key: 'isOnline',
							name: '是否在线',
							minWidth: 100,
							render: (h, row) => {
								return h(
									'span',
									{},
									isBlank(row.isOnline) ? '--' : nameTrans(row.isOnline, this.isOnlineOptions),
								)
							},
						},
						{
							key: 'isAlarm',
							name: '是否告警',
							minWidth: 100,
							render: (h, row) => {
								return h(
									'span',
									{
										style: {
											color: !isBlank(row.isAlarm) && row.isAlarm == 0 ? '#E5662E' : '#4e4e4e',
										},
									},
									isBlank(row.isAlarm) ? '--' : nameTrans(row.isAlarm, this.isAlarmOptions),
								)
							},
						},
						{
							key: 'lastCommunicateTime',
							name: '最后通讯时间',
							minWidth: 160,
						},
						{
							key: 'oprate',
							name: '操作',
							width: '250px',
							fixed: 'right',
							render: (h, row) => {
								// 根据安装状态进行动态变化
								// 如果状态是异常则展示或者没有任何操作权限  /
								if (isBlank(row.deviceStatus)) {
									return h('span', { style: { color: '#CCCCCC' } }, '/')
								}
								let style = {
									'font-size': '14px',
									'font-family': 'SourceHanSansCN-Regular, SourceHanSansCN',
									'font-weight': '400',
									color: '#4A69FF',
									'line-height': '21px',
									'margin-right': '20px',
									cursor: 'pointer',
								}
								return h('div', {}, [
									...[
										this.$has(deviceDetailPermissions)
											? [
													h(
														'span',
														{
															on: {
																click: () => this.checkDetail(row),
															},
															style,
														},
														'查看详情',
													),
											  ]
											: [],
									],
									...[
										row.deviceStatus == '2' || !this.$has('monitor:device:account:maintain')
											? []
											: [
													h(
														'span',
														{
															on: {
																click: () => this.changeDevice(row),
															},
															style,
														},
														'修改',
													),
											  ],
									],
									...[
										row.deviceStatus == '1' && this.$has('monitor:device:account:maintain')
											? [
													h(
														'span',
														{
															on: {
																click: () => this.dealDevice(row, row.deviceStatus),
															},
															// 状态时待安装则展示安装，为已安装则展示拆除
															style: {
																...style,
																...{
																	color: '#E5662E',
																},
															},
														},
														'拆除',
													),
											  ]
											: [],
									],
									...[
										row.deviceStatus != '1' && this.$has('monitor:device:account:maintain')
											? [
													h(
														'span',
														{
															on: {
																click: () => this.dealDevice(row, row.deviceStatus),
															},
															// 状态时待安装则展示安装，为已安装则展示拆除
															style: {
																...style,
																...{
																	color: '#19BE6B',
																},
															},
														},
														'安装',
													),
											  ]
											: [],
									],
									...[
										row.deviceStatus != '1' && this.$has('monitor:device:account:maintain')
											? [
													h(
														'span',
														{
															on: {
																click: () => this.delete(row),
															},
															style: { ...style, ...{ color: '#E5662E' } },
														},
														'删除',
													),
											  ]
											: [],
									],
								])
							},
						},
					]
				} else if (newVal === '0') {
					this.columns = [
						{
							key: 'deviceNo',
							name: '设备编号',
							tooltip: true,
							fixed: 'left',
							dragDisable: true,
							disabledSelect: true,
						},
						{
							key: 'gasDeviceNo',
							name: '条形编码',
							tooltip: true,
						},
						{
							key: 'deviceTypeName',
							name: '设备类型名称',
							tooltip: true,
						},
						{
							key: 'userNo',
							name: '用户号',
							tooltip: true,
						},
						{
							key: 'username',
							name: '客户名称',
							tooltip: true,
						},
						{
							key: 'addressName',
							name: '安装地址',
							tooltip: true,
							width: 200,
						},
						{
							key: 'installDate',
							name: '安装日期',
						},
						{
							key: 'manufacturerName',
							name: '设备厂商',
							tooltip: true,
						},
						{
							key: 'deviceStatusName',
							name: '设备状态',
							render: (h, row) => {
								let val = row.deviceStatus
								let statusMap = {
									0: { class: 'dot-error', text: '待安装' },
									1: { class: 'dot-success', text: '已安装' },
									2: { class: 'dot-message', text: '已拆除' },
								}
								return h(
									'span',
									{ class: isBlank(val) ? '' : statusMap[val]['class'] },
									isBlank(val) ? '--' : statusMap[val]['text'],
								)
							},
						},
						{
							key: 'isOnline',
							name: '是否在线',
							width: 100,
							render: (h, row) => {
								return h(
									'span',
									{},
									isBlank(row.isOnline) ? '--' : nameTrans(row.isOnline, this.isOnlineOptions),
								)
							},
						},
						{
							key: 'isAlarm',
							name: '是否告警',
							width: 100,
							render: (h, row) => {
								return h(
									'span',
									{
										style: {
											color: !isBlank(row.isAlarm) && row.isAlarm == 0 ? '#E5662E' : '#4e4e4e',
										},
									},
									isBlank(row.isAlarm) ? '--' : nameTrans(row.isAlarm, this.isAlarmOptions),
								)
							},
						},
						{
							key: 'lastCommunicateTime',
							name: '最后通讯时间',
							width: 160,
						},
						{
							key: 'oprate',
							name: '操作',
							width: '250px',
							fixed: 'right',
							render: (h, row) => {
								// 根据安装状态进行动态变化
								// 如果状态是异常则展示或者没有任何操作权限  /
								if (isBlank(row.deviceStatus)) {
									return h('span', { style: { color: '#CCCCCC' } }, '/')
								}
								let style = {
									'font-size': '14px',
									'font-family': 'SourceHanSansCN-Regular, SourceHanSansCN',
									'font-weight': '400',
									color: '#4A69FF',
									'line-height': '21px',
									'margin-right': '20px',
									cursor: 'pointer',
								}
								return h('div', {}, [
									...[
										this.$has(deviceDetailPermissions)
											? [
													h(
														'span',
														{
															on: {
																click: () => this.checkDetail(row),
															},
															style,
														},
														'查看详情',
													),
											  ]
											: [],
									],
									...[
										row.deviceStatus == '2' || !this.$has('monitor:device:account:maintain')
											? []
											: [
													h(
														'span',
														{
															on: {
																click: () => this.changeDevice(row),
															},
															style,
														},
														'修改',
													),
											  ],
									],
									...[
										row.deviceStatus == '1' && this.$has('monitor:device:account:maintain')
											? [
													h(
														'span',
														{
															on: {
																click: () => this.dealDevice(row, row.deviceStatus),
															},
															// 状态时待安装则展示安装，为已安装则展示拆除
															style: {
																...style,
																...{
																	color: '#E5662E',
																},
															},
														},
														'拆除',
													),
											  ]
											: [],
									],
									...[
										row.deviceStatus != '1' && this.$has('monitor:device:account:maintain')
											? [
													h(
														'span',
														{
															on: {
																click: () => this.dealDevice(row, row.deviceStatus),
															},
															// 状态时待安装则展示安装，为已安装则展示拆除
															style: {
																...style,
																...{
																	color: '#19BE6B',
																},
															},
														},
														'安装',
													),
											  ]
											: [],
									],
									...[
										row.deviceStatus != '1' && this.$has('monitor:device:account:maintain')
											? [
													h(
														'span',
														{
															on: {
																click: () => this.delete(row),
															},
															style: { ...style, ...{ color: '#E5662E' } },
														},
														'删除',
													),
											  ]
											: [],
									],
								])
							},
						},
					]
				}
			},
			immediate: true,
		},
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			let needRefresh = vm.$store.getters.needRefresh
			if (needRefresh) {
				vm.reset()
				vm.$store.dispatch('account/changeStatus', false)
			}
		})
	},
}
</script>
<style lang="scss" scoped>
.device-account {
	@include base-button(80px);
	height: 100%;
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	.content {
		flex: 1;
		display: flex;
		padding: 0 12px;
		height: 0;
		.left {
			width: 300px;
			height: 100%;
			padding: 20px 8px 8px 0;
			border-right: 1px solid #f1f1f1;
			& + .right {
				padding-left: 20px;
			}
		}
		.right {
			flex: 1;
			width: 0;
			display: flex;
			height: 100%;
			flex-direction: column;
			.filter {
				display: flex;
				flex-wrap: wrap;
				padding-top: 20px;
				.search {
					margin-right: 20px;
					margin-bottom: 14px;
				}
			}
			.table {
				flex: 1;
				height: 0;
			}
		}
	}
}
</style>
